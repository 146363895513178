/* config */
body,
html {
  margin: 0;
  padding: 0; }

html {
  user-select: none;
  box-sizing: border-box;
  border-collapse: collapse; }

* {
  box-sizing: border-box;
  border-collapse: inherit;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent; }

*,
*:after,
*:before {
  box-sizing: inherit; }

*:not(body) {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover; }

menu,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none; }

li {
  margin: 0;
  padding: 0; }

a {
  outline: none;
  color: inherit;
  text-decoration: none; }

img {
  width: 100%;
  border: none;
  display: block;
  pointer-events: none; }

p,
small {
  margin: 0;
  padding: 0; }

figure,
form {
  margin: 0; }

fieldset {
  border: none;
  margin: 0;
  padding: 0; }

blockquote,
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  font-weight: normal; }

hr {
  margin: 0;
  border: 0; }

button {
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer; }

main {
  position: relative; }

button {
  border: none;
  outline: none;
  background: transparent; }

section {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: auto;
  margin: auto;
  position: fixed;
  will-change: transform;
  backface-visibility: hidden; }

@font-face {
  font-family: 'Nitti Eindhoven';
  src: url(..//fonts/NittiEindhoven-Black.woff2) format("woff2"), url(..//fonts/NittiEindhoven-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Nitti Eindhoven';
  src: url(..//fonts/NittiEindhoven-Medium.woff2) format("woff2"), url(..//fonts/NittiEindhoven-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal; }

html, body {
  overscroll-behavior-y: none; }

.push {
  position: relative;
  overflow: hidden; }
  .push.push--down {
    padding-top: 100px; }
    @media only screen and (max-width: 991px) {
      .push.push--down {
        padding-top: 95px; } }

.overlay {
  position: fixed;
  z-index: 9009;
  background-color: transparent;
  width: 100%;
  height: 100vh;
  display: none; }
  .overlay .col {
    width: 8.33333%;
    height: 100vh;
    border-right: 1px #226986 solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    float: left;
    display: block; }
  .overlay.active {
    display: block; }

.logo {
  font-size: 1.74vw;
  line-height: 85%;
  text-transform: uppercase;
  font-weight: 900; }
  @media only screen and (max-width: 991px) {
    .logo {
      font-size: 3.255vw; } }
  @media only screen and (max-width: 479px) {
    .logo {
      font-size: 5.208vw; } }

.grecaptcha-badge {
  display: none !important; }

.button {
  display: inline-block;
  padding: 20px 25px;
  border: 3px solid;
  overflow: hidden;
  border-radius: 0;
  -webkit-appearance: none;
  line-height: 117.64706%;
  font-size: calc((17 / 1440) * 100vw); }
  @media only screen and (max-width: 991px) {
    .button {
      line-height: 125%;
      font-size: calc((16 / 992) * 100vw); } }
  @media only screen and (max-width: 479px) {
    .button {
      line-height: 125%;
      font-size: calc((24 / 480) * 100vw); } }
  .button .button__text {
    position: relative;
    z-index: 1;
    transition: color 300ms cubic-bezier(1, 0, 0, 1);
    font-family: 'Lato', sans-serif !important; }
  .button.button--squished {
    padding: 15px 45px; }
  .button.button--squished--large {
    padding: 15px 60px; }

.button--animated {
  overflow: hidden;
  position: relative; }
  .button--animated:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    transition: transform 300ms cubic-bezier(1, 0, 0, 1);
    z-index: 0;
    left: 0;
    top: 0;
    transform: translateY(100%); }
  .button--animated:hover:before {
    transform: translateY(0%); }

.button--black {
  color: #1d1d1b;
  border-color: #1d1d1b; }
  .button--black:before {
    background-color: #1d1d1b; }
  .button--black:hover .button__text {
    color: white; }

.button--white {
  color: white;
  border-color: white; }
  .button--white:before {
    background-color: white; }
  .button--white:hover .button__text {
    color: #1d1d1b; }

.button--select {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  background-image: url(..//img/icon-chevron.svg);
  background-size: 20px;
  background-position: 90%;
  background-color: transparent;
  font-weight: 900; }
  .button--select.button--white {
    background-image: url(..//img/icon-chevron-white.svg); }
  .button--select.button--squished {
    padding: 15px 60px 15px 30px; }
  @media only screen and (max-width: 991px) {
    .button--select {
      font-size: 1.18vw; } }
  @media only screen and (max-width: 767px) {
    .button--select {
      font-size: 2.44vw; } }

.button--transparent {
  border: 0;
  padding: 0; }

.button--red {
  background-color: #226986;
  color: white;
  border-color: transparent;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1); }
  .button--red:hover {
    color: #226986;
    background-color: white;
    border-color: #226986; }

body {
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  color: #1d1d1b;
  word-break: break-word; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

.heading--large {
  font-weight: 400;
  text-transform: uppercase;
  hyphens: manual;
  line-height: 103.44828%;
  font-family: "Lato", sans-serif;
  font-size: calc((58 / 1440) * 100vw); }
  @media only screen and (max-width: 991px) {
    .heading--large {
      line-height: 111.11111%;
      font-size: calc((40 / 992) * 100vw); } }
  @media only screen and (max-width: 479px) {
    .heading--large {
      line-height: 100%;
      font-size: calc((40 / 480) * 100vw); } }

.heading--medium {
  font-size: 4.167vw;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 85%; }
  @media only screen and (max-width: 479px) {
    .heading--medium {
      font-size: 8vw;
      margin-bottom: 20px; } }

.heading--small {
  line-height: 117.64706%;
  font-weight: 900;
  font-family: "Nitti Eindhoven";
  text-transform: uppercase;
  font-size: calc((17 / 1440) * 100vw); }
  @media only screen and (max-width: 991px) {
    .heading--small {
      line-height: 133.33333%;
      font-size: calc((22 / 992) * 100vw); } }
  @media only screen and (max-width: 479px) {
    .heading--small {
      line-height: 133.33333%;
      font-size: calc((25 / 480) * 100vw); } }

.heading--smallest {
  font-size: 1.74vw;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 85%; }
  @media only screen and (max-width: 991px) {
    .heading--smallest {
      font-size: 3.255vw; } }
  @media only screen and (max-width: 479px) {
    .heading--smallest {
      font-size: 6.042vw; } }

.heading--smaller {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  line-height: 117.64706%;
  font-size: calc((17 / 1440) * 100vw); }
  @media only screen and (max-width: 991px) {
    .heading--smaller {
      line-height: 125%;
      font-size: calc((16 / 992) * 100vw); } }
  @media only screen and (max-width: 479px) {
    .heading--smaller {
      line-height: 125%;
      font-size: calc((16 / 480) * 100vw); } }

.heading--push {
  margin-bottom: 45px; }

.paragraph {
  line-height: 156.25%;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: calc((16 / 1440) * 100vw); }
  @media only screen and (max-width: 991px) {
    .paragraph {
      line-height: 146.66667%;
      font-size: calc((21 / 992) * 100vw); } }
  @media only screen and (max-width: 479px) {
    .paragraph {
      line-height: 146.66667%;
      font-size: calc((24 / 480) * 100vw); } }
  .paragraph p {
    margin-bottom: 30px; }
    .paragraph p:last-of-type {
      margin-bottom: 0; }
    .paragraph p a {
      color: #226986;
      text-decoration: underline; }

.heading--special {
  display: block;
  position: relative;
  padding-left: 15px; }
  .heading--special:before {
    content: "";
    width: 3px;
    background-color: #226986;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0; }

.label {
  padding: 5px 6.66667px;
  color: white;
  font-weight: 400;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
  background-color: #226986;
  text-transform: uppercase;
  line-height: 183.33333%;
  font-size: calc((12 / 1440) * 100vw); }
  @media only screen and (max-width: 991px) {
    .label {
      line-height: 183.33333%;
      font-size: calc((14 / 992) * 100vw); } }
  @media only screen and (max-width: 479px) {
    .label {
      line-height: 183.33333%;
      font-size: calc((16 / 480) * 100vw); } }

.color--red {
  color: #226986; }

.color--black {
  color: #1d1d1b; }

.color--blue {
  color: #72737f; }

.tiles--blue .color--blue {
  color: #1d1d1b; }

.color--white {
  color: white; }

.color--grey {
  color: #f3f3f4; }

.grid {
  display: inline-block; }
  .grid .grid--1 {
    width: 8.33333%; }
  .grid .grid--2 {
    width: 16.66667%; }
  .grid .grid--3 {
    width: 25%; }
  .grid .grid--4 {
    width: 33.33333%; }
  .grid .grid--5 {
    width: 41.66667%; }
  .grid .grid--6 {
    width: 50%; }
  .grid .grid--7 {
    width: 58.33333%; }
  .grid .grid--8 {
    width: 66.66667%; }
  .grid .grid--9 {
    width: 75%; }
  .grid .grid--10 {
    width: 83.33333%; }
  .grid .grid--11 {
    width: 91.66667%; }
  .grid .grid--12 {
    width: 100%; }

/* components */
.banner {
  padding: 200px 0 160px 0;
  background-color: white;
  display: flex; }
  @media only screen and (max-width: 991px) {
    .banner {
      padding: 100px 8.33333%;
      flex-flow: row;
      flex-wrap: wrap; } }
  @media only screen and (max-width: 479px) {
    .banner {
      padding: 60px 8.33333%;
      margin-top: 95px; } }

.banner__article {
  display: block;
  float: left; }
  .banner__article.banner__article--titles {
    width: 41.66667%;
    margin-left: 8.33333%; }
  .banner__article.banner__article--paragraphs {
    width: 25%;
    margin: 0 16.66667% 0 8.33333%; }
  @media only screen and (max-width: 991px) {
    .banner__article {
      margin: 30px 0 !important; }
      .banner__article.banner__article--paragraphs, .banner__article.banner__article--titles {
        flex-basis: 100%; } }
  @media only screen and (max-width: 479px) {
    .banner__article.banner__article--paragraphs, .banner__article.banner__article--titles {
      flex: 0 0 100%; } }

.banner__article-paragraph p {
  color: #72737f; }
  .banner__article-paragraph p strong {
    font-size: 1.458vw;
    color: #1d1d1b; }
    @media only screen and (max-width: 1179px) {
      .banner__article-paragraph p strong {
        font-size: 1.695vw; } }
    @media only screen and (max-width: 991px) {
      .banner__article-paragraph p strong {
        font-size: 2.604vw; } }
    @media only screen and (max-width: 479px) {
      .banner__article-paragraph p strong {
        font-size: 5.208vw; } }
  .banner__article-paragraph p a {
    color: #226986;
    text-decoration: underline; }

.banner__article-heading--small {
  margin-bottom: 20px; }

.cookie {
  position: fixed;
  z-index: 999;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 15px 0;
  margin: 0;
  background: #1d1d1b;
  text-align: center; }
  .cookie p {
    display: inline-block;
    margin: 0;
    padding: 0 15px;
    color: white; }
  .cookie form {
    display: inline-block; }
    .cookie form input {
      border: none;
      cursor: pointer;
      background: white;
      margin: 20px 0 10px 0;
      padding: 10px 20px;
      font-weight: 600;
      outline: none; }

@media only screen and (max-width: 991px) {
  .grecaptcha-badge {
    left: 0 !important;
    width: 70px !important;
    box-shadow: none !important;
    overflow: hidden;
    bottom: auto !important;
    top: 120px !important; } }

.contact-form {
  padding: 120px 0 100px;
  position: relative;
  display: flex; }
  .contact-form input,
  .contact-form select,
  .contact-form textarea,
  .contact-form button,
  .contact-form label {
    font-family: 'Lato', sans-serif; }
  .contact-form .contact-form__right br {
    display: none; }
  .contact-form label {
    font-size: 1.458vw;
    color: #1d1d1b;
    font-weight: 900;
    margin-bottom: 15px;
    text-transform: uppercase;
    display: block; }
    @media only screen and (max-width: 991px) {
      .contact-form label {
        font-size: 2.604vw; } }
    @media only screen and (max-width: 479px) {
      .contact-form label {
        font-size: 2.604vw; } }
  .contact-form .wpcf7-not-valid-tip {
    display: block;
    margin-bottom: 10px; }
  .contact-form div.wpcf7-validation-errors, .contact-form div.wpcf7-acceptance-missing {
    margin: 0;
    padding: 0;
    margin: 30px 0;
    float: right;
    border-color: #226986;
    padding: 10px;
    width: 100%; }
  @media only screen and (max-width: 1179px) {
    .contact-form {
      padding-top: 25vh; } }
  @media only screen and (max-width: 991px) {
    .contact-form {
      padding: 60px 8.33333%;
      flex-wrap: wrap; } }
  @media only screen and (max-width: 479px) {
    .contact-form {
      padding-top: 110px;
      top: 40px; } }

.contact-form__article {
  width: 58.33333%;
  padding: 0 8.33333%; }
  .contact-form__article .contact-form__article-paragraph {
    margin-top: 40px;
    padding-right: 80px; }
    @media only screen and (max-width: 479px) {
      .contact-form__article .contact-form__article-paragraph {
        padding-right: 0; } }
  @media only screen and (max-width: 991px) {
    .contact-form__article {
      width: unset;
      flex-basis: 100%;
      padding: 0;
      margin-bottom: 60px; } }

.contact-form__right {
  width: 41.66667%;
  padding-right: 8.33333%; }
  @media only screen and (max-width: 991px) {
    .contact-form__right {
      width: 100%;
      padding-right: 0; } }

.contact-form__input {
  display: block; }

.input {
  border: 3.33333px #1d1d1b solid;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  display: block;
  padding: 15px 25px;
  width: 100%;
  font-size: 1.11vw;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 20px;
  resize: none;
  border-radius: 0;
  background-color: transparent; }
  .input.input--textarea {
    min-height: 200px; }
  .input.input--file {
    padding: 15px 0;
    border: none; }
    .input.input--file::-webkit-file-upload-button {
      color: white; }
  @media only screen and (max-width: 991px) {
    .input {
      font-size: 1.864vw; } }
  @media only screen and (max-width: 479px) {
    .input {
      font-size: 2.604vw; } }

.contact-form__button {
  float: right;
  font-size: 0.833vw;
  text-transform: uppercase;
  padding: 20px 40px;
  cursor: pointer;
  outline: none; }
  @media only screen and (max-width: 991px) {
    .contact-form__button {
      font-size: 1.864vw; } }
  @media only screen and (max-width: 479px) {
    .contact-form__button {
      font-size: 2.604vw; } }

.contact-form__select {
  width: 100%;
  padding: 15px 25px;
  margin-bottom: 20px;
  background-position: 95%;
  background-color: transparent; }
  @media only screen and (max-width: 991px) {
    .contact-form__select {
      font-size: 1.864vw; } }
  @media only screen and (max-width: 479px) {
    .contact-form__select {
      font-size: 2.604vw; } }

.contact-form__file {
  display: block;
  margin-bottom: 20px; }

.footer {
  padding: 80px 8.33333% 40px 8.33333%;
  background: white;
  position: relative;
  display: flex; }
  @media only screen and (max-width: 479px) {
    .footer {
      font-size: 5.208vw; } }

.footer__half {
  flex-basis: 50%;
  display: flex;
  align-items: center; }
  .footer__half:last-of-type {
    justify-content: flex-end; }
  .footer__half ul.footer__social {
    display: flex;
    align-items: center; }
    .footer__half ul.footer__social li {
      width: 25px; }
      .footer__half ul.footer__social li:nth-of-type(2) {
        width: 12px;
        margin: 0 20px; }

.footer__button {
  font-size: 2.0835vw;
  display: inline-block; }
  @media only screen and (max-width: 991px) {
    .footer__button {
      font-size: 3.255vw; } }
  @media only screen and (max-width: 479px) {
    .footer__button {
      font-size: 5.208vw; } }

.footer__logo {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
  background-position: right;
  margin-bottom: 30px; }
  @media only screen and (max-width: 479px) {
    .footer__logo {
      margin-bottom: 20px; } }
  .footer__logo:last-child {
    margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .footer__logo {
      position: relative;
      right: auto;
      background-position: right; } }

.footer__button, .footer__logo {
  margin-left: -20px; }
  @media only screen and (max-width: 767px) {
    .footer__button, .footer__logo {
      margin-left: 0; } }
  .footer__button svg, .footer__logo svg {
    width: calc((200 / 1440) * 100vw);
    max-width: 200px; }
    @media only screen and (max-width: 767px) {
      .footer__button svg, .footer__logo svg {
        width: calc((200 / 992) * 100vw); } }
  @media only screen and (max-width: 479px) {
    .footer__button, .footer__logo {
      display: block;
      width: 100%; } }
  .footer__button:last-child, .footer__logo:last-child {
    margin-bottom: 0; }

.header {
  width: 100%;
  height: auto;
  background-color: #1d1d1b;
  padding: 15% 8.33333%;
  position: relative;
  overflow: hidden; }
  .header.header--text {
    padding-top: 220px; }
    @media only screen and (max-width: 991px) {
      .header.header--text {
        top: unset;
        padding-top: 0; } }
    @media only screen and (max-width: 479px) {
      .header.header--text {
        padding-top: 155px;
        margin-bottom: 60px; } }
  .header.background--white {
    background-color: white; }
  .header.background--black {
    background-color: #1d1d1b; }
  @media only screen and (max-width: 1024px) {
    .header {
      height: 50vh; } }
  @media only screen and (max-width: 991px) {
    .header {
      height: auto;
      margin-bottom: 0; } }
  @media only screen and (max-width: 479px) {
    .header {
      margin-top: 0;
      margin-bottom: 0;
      padding: 35% 8.33333%; } }

.header__article {
  width: 50%;
  padding-right: 8.33333%;
  padding-top: 8.33333%;
  padding-bottom: 8.33333%;
  z-index: 1;
  position: relative; }
  .header__article .header__article-paragraph {
    margin-top: 30px;
    padding-right: 90px; }
    @media only screen and (max-width: 479px) {
      .header__article .header__article-paragraph {
        padding-right: 0; } }
  @media only screen and (max-width: 991px) {
    .header__article {
      width: 83.33333%;
      padding-right: 30px;
      position: relative; } }
  @media only screen and (max-width: 991px) {
    .header__article {
      width: 100%;
      padding: 120px 0; } }
  @media only screen and (max-width: 479px) {
    .header__article {
      padding: 0; } }

.header__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }
  .header__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.nav {
  width: 100%;
  height: 10vh;
  position: absolute;
  z-index: 1000;
  padding: 0 8.33333%;
  display: none; }
  @media only screen and (max-width: 1179px) {
    .nav {
      height: 95px; } }
  @media only screen and (max-width: 991px) {
    .nav {
      background: #1d1d1b;
      position: fixed;
      top: 0; } }
  .nav .nav__button {
    display: table;
    font-weight: 900; }
    .nav .nav__button svg {
      width: calc((180 / 1440) * 100vw); }
      @media only screen and (max-width: 991px) {
        .nav .nav__button svg {
          fill: white;
          width: calc((200 / 992) * 100vw); } }
      @media only screen and (max-width: 767px) {
        .nav .nav__button svg {
          fill: white;
          width: calc((250 / 992) * 100vw); } }
      @media only screen and (max-width: 479px) {
        .nav .nav__button svg {
          width: calc((300 / 768) * 100vw); } }
    .nav .nav__button.nav__button--toggle {
      position: absolute;
      right: 8.33333%;
      margin-top: 40px; }
    @media only screen and (max-width: 991px) {
      .nav .nav__button .logo {
        color: white; } }
  .nav.nav--hidden {
    position: fixed;
    height: 100px;
    background: white;
    display: flex; }

.nav__list {
  width: 75%;
  text-align: left;
  padding: 0 90px;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 479px) {
    .nav__list {
      display: none; } }

.nav__list-item {
  display: inline; }

.nav__list-link {
  margin: 0 30px;
  font-size: 1.18vw;
  font-weight: 900; }
  @media only screen and (max-width: 1024px) {
    .nav__list-link {
      margin: 0 20px; } }
  @media only screen and (max-width: 1179px) {
    .nav__list-link {
      font-size: 1.55vw; } }

.menu {
  height: 100vh;
  width: 58.33333%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #1d1d1b;
  z-index: 1001;
  padding: 10vh 8.33333%;
  backface-visibility: hidden;
  will-change: transform; }
  .menu.js-opened {
    z-index: 99999; }
  .menu .menu__button {
    float: right;
    transform: translateY(-100%); }
    @media only screen and (max-width: 991px) {
      .menu .menu__button {
        transform: translateY(-50%); } }
  @media only screen and (max-width: 991px) {
    .menu {
      width: 100%;
      padding: 45px 8.33333%; } }

.menu__center {
  display: table;
  width: 100%;
  height: 100%; }

.menu__list {
  height: 100%;
  width: 100%;
  display: table-cell;
  vertical-align: middle; }
  .menu__list .menu__list--item {
    display: block; }
    .menu__list .menu__list--item .menu__list--link {
      display: block;
      opacity: 0;
      backface-visibility: hidden;
      will-change: transform;
      margin-bottom: 10px; }
      .menu__list .menu__list--item .menu__list--link .heading--large {
        font-size: 2.25vw; }
        @media only screen and (max-width: 991px) {
          .menu__list .menu__list--item .menu__list--link .heading--large {
            font-size: 6.7vw; } }
      .menu__list .menu__list--item .menu__list--link:hover .heading--large {
        color: #226986; }

.menu__languages {
  position: absolute; }
  .menu__languages li {
    margin-bottom: 5px; }
    .menu__languages li.current-lang a {
      color: #226986; }
    .menu__languages li a {
      color: white;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 400; }
      .menu__languages li a:hover {
        color: #226986; }
  .menu__languages.menu__languages--sticky {
    right: 8.33333%;
    top: 10px;
    color: #1d1d1b;
    text-align: center; }
    .menu__languages.menu__languages--sticky li {
      margin-bottom: 0;
      display: inline-block;
      margin-right: 10px; }
      .menu__languages.menu__languages--sticky li:last-child {
        margin-right: 0; }
      .menu__languages.menu__languages--sticky li a {
        color: #1d1d1b; }
      .menu__languages.menu__languages--sticky li.current-lang a {
        color: #226986; }
  .menu__languages.menu__languages--white li a {
    color: white; }
  .menu__languages.menu__languages--white li.current-lang a {
    color: #226986; }

.slider {
  width: 100%;
  display: flex;
  position: relative; }
  .slider.slider--large {
    height: 100vh; }
    @media only screen and (max-width: 991px) {
      .slider.slider--large {
        padding-top: 95px; } }
  .slider.slider--small {
    height: 50vw; }

.slider__slide {
  width: 100vw;
  height: 100vw;
  display: table; }

.slider__logo {
  position: absolute;
  width: calc((200 / 1440) * 100vw);
  left: 8.33333%;
  bottom: 60px;
  z-index: 2; }
  @media only screen and (max-width: 767px) {
    .slider__logo {
      width: calc((250 / 768) * 100vw); } }

.slider__image {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 0; }
  .slider__image img {
    height: 100%;
    object-fit: cover;
    object-position: center; }
    @media only screen and (max-width: 1024px) {
      .slider__image img {
        object-fit: cover;
        object-position: top; } }
  @media only screen and (max-width: 991px) {
    .slider__image {
      z-index: 1;
      width: 100%;
      right: 0; } }

.slider__controls {
  position: absolute;
  right: 8.33333%;
  top: 50%;
  transform: translateY(-100%);
  z-index: 5;
  bottom: auto !important;
  left: auto !important;
  width: auto !important; }
  .slider__controls .slider__controls-item {
    display: block;
    width: 15px;
    padding-top: 100%;
    background-color: #1d1d1b;
    margin-bottom: 20px;
    cursor: pointer;
    transition: background-color 200ms ease; }
    .slider__controls .slider__controls-item:hover {
      background-color: white; }
    .slider__controls .slider__controls-item.js-active-slide {
      background-color: white; }

.slider--grey {
  background-color: #f3f3f4; }

.slider__button {
  position: absolute;
  right: 8.33333%;
  bottom: 8.33vw;
  z-index: 2; }
  @media only screen and (max-width: 991px) {
    .slider__button {
      right: auto;
      left: 8.33333%; } }

.slider__article {
  width: 50%;
  padding-left: 8.33333%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  left: 0;
  z-index: 1; }
  .slider__article .slider__article-paragraph {
    margin: 40px 0 0 0;
    color: #72737f; }
    .slider__article .slider__article-paragraph a {
      color: #1d1d1b;
      font-weight: 900;
      text-decoration: underline;
      text-transform: uppercase; }
    @media only screen and (max-width: 479px) {
      .slider__article .slider__article-paragraph p {
        line-height: 125%; } }
    .slider__article .slider__article-paragraph p:last-of-type {
      margin-bottom: 0; }
    @media only screen and (max-width: 479px) {
      .slider__article .slider__article-paragraph {
        margin: 20px 0 0; } }
  @media only screen and (max-width: 991px) {
    .slider__article {
      width: 80%;
      padding: 0 0 0 8.33333%;
      z-index: 2; } }
  @media only screen and (max-width: 479px) {
    .slider__article {
      top: 100px;
      transform: none; } }

.swiper-container {
  width: 100%;
  height: 100%; }

.swiper-slide {
  background: #f3f3f4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.swiper-wrapper {
  box-sizing: border-box; }

.text {
  background-color: white;
  padding: 120px 0; }
  @media only screen and (max-width: 991px) {
    .text {
      padding: 100px 8.33333%; } }

.text__article {
  padding: 0 25%; }
  @media only screen and (max-width: 991px) {
    .text__article {
      padding: 0; } }
  .text__article .text__article-heading--small {
    margin-bottom: 15px; }
  .text__article .text__article-heading {
    margin-bottom: 45px; }
  .text__article .text__article-heading--special {
    margin-bottom: 20px; }
  .text__article .text__article-paragraph {
    color: #72737f;
    margin: 20px 0; }

.text__image {
  position: relative;
  margin: 60px 16.66667%; }
  @media only screen and (max-width: 1179px) {
    .text__image .label {
      font-size: 1.18vw; } }
  @media only screen and (max-width: 991px) {
    .text__image {
      margin: 60px 0; } }
  @media only screen and (max-width: 767px) {
    .text__image .label {
      font-size: 2.604vw; } }

.text-image {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap; }
  .text-image.text-image--black {
    background-color: #1d1d1b; }
    .text-image.text-image--black:after {
      top: 0;
      width: 100%;
      height: 100%;
      content: '';
      position: absolute; }
  @media only screen and (max-width: 479px) {
    .text-image {
      flex-flow: row;
      flex-wrap: wrap; } }
  .text-image.text--left:after {
    left: 0;
    background: linear-gradient(to left, rgba(17, 17, 18, 0) 30%, #111112 60%); }
  .text-image.text--right:after {
    right: 0;
    background: linear-gradient(to right, rgba(17, 17, 18, 0) 30%, #111112 60%); }

.text-image__logo {
  width: calc((200 / 1440) * 100vw);
  left: 8.33333%;
  z-index: 2;
  margin-top: 45px; }
  @media only screen and (max-width: 767px) {
    .text-image__logo {
      width: calc((250 / 768) * 100vw); } }

.text-image__image {
  flex-basis: 50%;
  position: relative;
  z-index: 0;
  display: block; }
  .text-image--fullwidth .text-image__image {
    height: 100%;
    width: 100%;
    position: absolute; }
  .text-image__image img {
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .text-image__image .text-image__label {
    left: 20px; }
  .text-image--small .text-image__image {
    height: auto;
    margin: 8.33333%;
    position: relative;
    flex-basis: 33.33333%; }
  @media only screen and (max-width: 479px) {
    .text-image__image {
      position: relative;
      flex: 0 0 100%; }
      .text-image--small .text-image__image {
        margin: 0; } }
  .text--left .text-image__image {
    order: 2; }
  .text--right .text-image__image {
    order: 1; }

.text-image__article,
.text-image__links {
  flex-basis: 50%;
  z-index: 1; }
  .text-image__article .text-image__article-heading--large,
  .text-image__article .text-image__links-heading,
  .text-image__links .text-image__article-heading--large,
  .text-image__links .text-image__links-heading {
    margin-bottom: 30px; }
    @media only screen and (max-width: 479px) {
      .text-image__article .text-image__article-heading--large,
      .text-image__article .text-image__links-heading,
      .text-image__links .text-image__article-heading--large,
      .text-image__links .text-image__links-heading {
        margin-bottom: 10px; } }
  .text-image__article .text-image__article-heading--small,
  .text-image__links .text-image__article-heading--small {
    margin-bottom: 30px; }
  .text-image__article .text-image__article-paragraph,
  .text-image__links .text-image__article-paragraph {
    color: white; }
    .text-image--white .text-image__article .text-image__article-paragraph, .text-image--white
    .text-image__links .text-image__article-paragraph {
      color: #72737f; }
  @media only screen and (max-width: 479px) {
    .text-image__article,
    .text-image__links {
      flex: 0 0 100%;
      margin-bottom: 60px;
      padding: 0 8.33333%; } }
  .text--left .text-image__article, .text--left
  .text-image__links {
    order: 1;
    text-align: left;
    padding: 140px 60px 140px 8.33333%; }
    @media only screen and (max-width: 767px) {
      .text--left .text-image__article, .text--left
      .text-image__links {
        padding: 60px 8.33%; } }
  .text--right .text-image__article, .text--right
  .text-image__links {
    padding: 140px 8.33333% 140px 60px;
    order: 2; }
    @media only screen and (max-width: 767px) {
      .text--right .text-image__article, .text--right
      .text-image__links {
        padding: 60px 8.33%; } }
  .text-image--fullwidth.text--left .text-image__article, .text-image--fullwidth.text--left
  .text-image__links {
    margin-right: 50%; }
  .text-image--fullwidth.text--right .text-image__article, .text-image--fullwidth.text--right
  .text-image__links {
    margin-left: 50%; }
    @media only screen and (max-width: 991px) {
      .text-image--fullwidth.text--right .text-image__article, .text-image--fullwidth.text--right
      .text-image__links {
        padding: 140px 4.16667% 140px 4.16667%; } }
    @media only screen and (max-width: 479px) {
      .text-image--fullwidth.text--right .text-image__article, .text-image--fullwidth.text--right
      .text-image__links {
        padding: 60px 8.33%; } }
  .text-image__article .button,
  .text-image__links .button {
    margin-top: 60px; }

@media only screen and (max-width: 767px) {
  .text-image__article,
  .text-image__links {
    flex-basis: 100% !important;
    margin: 0 !important;
    order: 2 !important; }
  .text-image__image {
    position: relative !important;
    flex-basis: 100% !important;
    z-index: 1;
    order: 1 !important; } }

@media only screen and (max-width: 479px) {
  .home .text-image.text--left.text-image--fullwidth .text-image__article {
    background: #28344c; } }

.text-image__links {
  flex-basis: 50%;
  padding: 140px 60px 100px 60px; }
  .text--left .text-image__links {
    order: 2; }
  .text--right .text-image__links {
    order: 1; }
  .text-image__links .text-image__links-heading {
    font-weight: 400;
    text-decoration: underline;
    color: #226986;
    margin-bottom: 15px;
    display: block;
    font-size: 3vw;
    line-height: 150%;
    word-break: normal; }
    @media only screen and (max-width: 767px) {
      .text-image__links .text-image__links-heading {
        font-size: 4vw; } }

.text-image__video img {
  position: absolute; }
  @media only screen and (max-width: 767px) {
    .text-image__video img {
      position: relative; } }

.text-image__video .text-image__video-button {
  width: 100px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%); }
  .text-image__video .text-image__video-button svg {
    fill: white; }

.modal {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  top: 100%;
  align-items: center; }
  .modal .modal__container {
    width: 90%;
    margin: 0 5%;
    height: 0;
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%; }
  .modal .modal__iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }

.modal__button--close {
  width: 30px;
  position: absolute;
  top: 30px;
  right: 30px; }
